/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import { Vue, settings } from "glib-web/index.js";
import '../../assets/stylesheets/application.css'

window.addEventListener("load", () => {
  navigator.serviceWorker.register("/service-worker.js");
});


// settings.gtagId = "UA-3931220-2";

settings.themes = {
  light: {
    primary: "#000000"
    // secondary: '#ff0000',
  }
};

import mixin from "custom/mixins/styling.js";
Vue.mixin(mixin);

import CommonTemplate from "custom/templates/common";
Vue.component("template-standard", CommonTemplate);

import PlatformFooterLayoutTemplate from "custom/templates/layouts/platformFooter";
Vue.component("template-layouts-platformFooter", PlatformFooterLayoutTemplate);
import PlatformHeaderLayoutTemplate from "custom/templates/layouts/platformHeader";
Vue.component("template-layouts-platformHeader", PlatformHeaderLayoutTemplate);

import MenuButton from "custom/templates/layouts/menuButton";
Vue.component("template-menu-button", MenuButton);

import Home from "custom/templates/layouts/home";
Vue.component("template-home", Home);

import Example from "custom/templates/layouts/example";
Vue.component("template-example", Example);

import WhatsappButton from "custom/templates/layouts/whatsappButton";
Vue.component("template-whatsapp-button", WhatsappButton);
