export default {
  computed: {
    appColorTabDark: function() {
      return "#7f561b";
    }
  },
  methods: {
    truncate_text: function(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    format_date: function(timestamp) {
      return timestamp.split("T")[0];
    }
  }
};
