<template>
  <div :class="spec.styleClasses">
    <v-toolbar elevation="0">
      <v-progress-circular
        v-if="$root.vueApp.indicator"
        indeterminate
      ></v-progress-circular>
      <v-toolbar-title v-else>
        <span class="text-intro">{{ spec.title }}</span>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: {
    spec: {
      type: Object,
      required: true
    }
  },
  computed: {
    logo() {
      return this.spec.logo;
    }
    // indicator() {
    //   return window.vueApp.indicator
    // }
  }
};
</script>

<style lang="scss" scoped>
// @import "../../../../../assets/stylesheets/_colors.scss";

header {
  padding-left: 16px;
  padding-right: 36px;
}
.text-intro {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 32px;
}
.v-btn--outlined .v-btn__content .v-icon,
.v-btn--round .v-btn__content .v-icon {
  color: #787c80;
}
</style>
