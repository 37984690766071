<template>
  <div class="footer">
    Copyright Ruang Profesor © {{ new Date().getFullYear() }}
    <div class="float-right">
      <template v-for="(item, index) in spec.rightButtons">
        <v-btn class="footer-button" v-if="item.text" text title :href="$href(item)" :target="_blank" :key="index">{{ item.text }}</v-btn>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['spec'],
  methods: {
    $ready() {},
  },
};
</script>

<style lang="scss" scoped>
// @import "../../../../../assets/stylesheets/_breakpoints.scss";
// @import "../../../../../assets/stylesheets/_colors.scss";

.footer {
  border-top: #cccccc 1px solid;
  a {
    text-decoration: none;
    &:hover {
      color: #008518;
    }
  }
  padding-top: 34px;
  padding-bottom: 34px;
  padding-left: 84px;
  padding-right: 84px;
}

.footer-button {
  text-transform: capitalize !important;
}


@media only screen and (max-width: 600px) {
  .footer {
    text-align: center;
    padding-top: 34px;
    padding-bottom: 34px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .float-right {
    text-align: center;
    float: none !important;
  }
}
</style>
