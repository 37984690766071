<template>
  <v-icon @click="toggle()">menu</v-icon>
</template>
<script>
import bus from "glib-web/utils/eventBus";

export default {
  props: {
    spec: { type: Object, required: true },
  },
  methods: {
    toggle() {
      bus.$emit("drawers/toggle");
    },
  },
};
</script>
