<!-- We use Vuetify2: https://v2.vuetifyjs.com/en/components/grids/ -->
<template>
  <div class="page">
    <header class="page__header pa-3">
      <v-btn :href="spec.dashboard_url" color="primary" outlined class="mr-2">Dashboard</v-btn>
      <v-btn :href="spec.login_url" color="secondary" outlined class="mr-2">Login</v-btn>
      <v-btn :href="spec.signup_url" color="success" outlined>Sign Up</v-btn>
    </header>
    
    <section class="page__hero pa-3">
      <v-carousel>
        <!-- Sample slides for carousel -->
        <v-carousel-item src="https://picsum.photos/1368/500"></v-carousel-item>
        <v-carousel-item src="https://picsum.photos/1368/500"></v-carousel-item>
      </v-carousel>
      <h1>Hero Title</h1>
      <p>Some hero description.</p>
    </section>

    <v-row class="page__content pa-3">
      <v-col cols="6">
        <v-img :src="spec.page_image" alt="Description of Image" max-height="200"></v-img>
      </v-col>
      <v-col cols="6">
        <p>{{ formattedText }}</p>
      </v-col>
    </v-row>

    <footer class="page__footer pa-3">
      <p>Footer content</p>
      <v-btn :href="spec.terms_url" color="secondary" outlined class="mr-2">Terms</v-btn>
      <v-btn :href="spec.privacy_url" color="success" outlined>Privacy</v-btn>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
  },
  computed: {
    formattedText() {
      return "Intro: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel sapien lectus. Aliquam consectetur vitae tortor mattis sodales. Donec id quam id libero consequat varius.";
    }
  }
};
</script>

<style lang="scss" scoped>
// Global styles are located at app/assets/stylesheets/vue_renderer_custom.scss

.page {
  // You can add block-level styles here
}

.page__hero {
  // Styling for the hero section
}

.page__content {
  // You can add block-level styles here for the content section if needed
}

.page__footer {
  // Styling for the footer
}
</style>
